import { useTheme } from '@emotion/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PropsWithChildren, useMemo } from 'react';
import { useCalloutsStore, useServicesStore } from 'store';

import { ManagedRate } from 'services/models/ManagedRate';
import { formatPrice, getDiscountAndFullPrice } from 'utils/formatPrice';
import { JOB_TYPES, queryParamToJobTypeMap } from 'utils/jobTypes';
import { currentPartner } from 'utils/partners';
import { getPriceFromJobType } from 'utils/tracking';
import { generateRateDomain } from 'utils/urls';
import { useManagedRates } from 'utils/useManagedRates';

import { StrongText } from '../../StrongText';
import { CheckMark } from '../CheckMark';
import { KantanLogo } from '../KantanLogo';
import { OvoLogo } from '../OvoLogo';
import { PostcodeInput } from '../PostcodeInput';
import {
  Check,
  Content,
  ContentEntry,
  ContentEntryText,
  FlexWrappedSpan,
  HeaderContainer,
  InputBox,
  LandingPageContentBox,
  Logo,
  Price,
  PriceContainer,
  Progress,
  Step,
  SubHeader,
  TrackerGrid,
  TrackerTypography,
  VATSubscript,
  VerticalDivider,
  WhiteBoldSmallerText,
  WhiteBoldText,
  WhiteLineThroughText,
} from './Tracker.styles';

const routeToStepMap = {
  '/': 0,
  '/fuel-type': 1,
  '/services': 2,
  '/select-time': 3,
  '/address': 4,
  '/resident': 5,
  '/review': 6,
  '/confirmation': 7,
};

type Steps = keyof typeof routeToStepMap;

const steps: Array<Steps> = [
  '/',
  '/fuel-type',
  '/services',
  '/select-time',
  '/address',
  '/resident',
  '/review',
  '/confirmation',
];

interface TrackerProps {
  showPrice?: boolean;
  currentRoute?: Steps;
  subTitle?: string;
  showSubtitle?: boolean;
  inactiveHeader?: boolean;
  mobileSubTitle?: string;
  simplified?: boolean;
  animationTriggerCallback?: (shouldTriggerAnimation: boolean) => void;
}

interface renderPriceProps {
  managedRates: Record<JOB_TYPES, ManagedRate> | undefined;
  isRepair: boolean;
  jobType: JOB_TYPES | string;
  isNewLanding?: boolean;
  showPerHour?: boolean;
}

//TODO(adrian.pop): extract and simplify
export const renderPrice = ({
  managedRates,
  isRepair,
  jobType,
  isNewLanding,
  showPerHour,
}: renderPriceProps) => {
  const heatingRepairPrice = getPriceFromJobType(
    JOB_TYPES.HEATING_REPAIR,
    managedRates,
  );
  const boilerServicePrice = getPriceFromJobType(
    JOB_TYPES.BOILER_SERVICE,
    managedRates,
  );
  const gasSafetyPrice = getPriceFromJobType(
    JOB_TYPES.GAS_SAFETY_CERTIFICATE,
    managedRates,
  );
  const comboPrice = getPriceFromJobType(
    JOB_TYPES.BOILER_SERVICE_COMBO,
    managedRates,
  );

  const plumbingRepairPrice = getPriceFromJobType(
    JOB_TYPES.PLUMBING_REPAIR,
    managedRates,
  );

  const { fullComboPrice, isPromoComboRate, prePromoComboAmount } =
    getDiscountAndFullPrice(managedRates);

  const renderPrice = (rate: ManagedRate) => {
    return rate.isPromoRate ? (
      <FlexWrappedSpan>
        <WhiteLineThroughText>
          £{formatPrice(rate.prePromoAmount ?? rate.amount)}
        </WhiteLineThroughText>
        <WhiteBoldText>
          £{formatPrice(rate.amount)}
          {showPerHour && <WhiteBoldSmallerText>/hr</WhiteBoldSmallerText>}
        </WhiteBoldText>
      </FlexWrappedSpan>
    ) : (
      <>
        £{formatPrice(rate.amount)}
        {showPerHour && <WhiteBoldSmallerText>/hr</WhiteBoldSmallerText>}
      </>
    );
  };

  const renderOriginalLandingPagePrice = (
    rate: ManagedRate,
    VATText: string,
  ) => {
    return (
      <Price>
        <StrongText weight="semiBold">
          Only{' '}
          {rate.isPromoRate && (
            <>
              <span id="strike-through">
                £{formatPrice(rate.prePromoAmount || rate.amount)}
              </span>{' '}
            </>
          )}
          <StrongText>£{formatPrice(rate.amount)}</StrongText>
        </StrongText>{' '}
        <VATSubscript>{VATText}</VATSubscript>
      </Price>
    );
  };

  switch (jobType) {
    case JOB_TYPES.HEATING_REPAIR:
      return (
        <>
          {isNewLanding
            ? renderPrice(heatingRepairPrice)
            : renderOriginalLandingPagePrice(
                heatingRepairPrice,
                heatingRepairPrice.isAmountWithVat ? 'inc. VAT*' : 'exc. VAT*',
              )}
        </>
      );
    case JOB_TYPES.BOILER_SERVICE:
      return (
        <>
          {isNewLanding
            ? renderPrice(boilerServicePrice)
            : renderOriginalLandingPagePrice(
                boilerServicePrice,
                boilerServicePrice.isAmountWithVat ? 'inc. VAT' : 'exc. VAT',
              )}
        </>
      );
    case JOB_TYPES.GAS_SAFETY_CERTIFICATE:
      return (
        <>
          {isNewLanding
            ? renderPrice(gasSafetyPrice)
            : renderOriginalLandingPagePrice(
                gasSafetyPrice,
                gasSafetyPrice.isAmountWithVat ? 'inc. VAT' : 'exc. VAT',
              )}
        </>
      );
    case JOB_TYPES.BOILER_SERVICE_COMBO:
      return (
        <>
          {isNewLanding ? (
            <>£{formatPrice(comboPrice.amount)}</>
          ) : (
            <Price $isCombo>
              <StrongText weight="semiBold">Only</StrongText>{' '}
              <span id="strike-through">
                £{isPromoComboRate ? prePromoComboAmount : fullComboPrice}
              </span>{' '}
              <StrongText>£{formatPrice(comboPrice.amount)}</StrongText>{' '}
              <VATSubscript>
                {comboPrice.isAmountWithVat ? 'inc. VAT' : 'exc. VAT'}
              </VATSubscript>
            </Price>
          )}
        </>
      );
    case JOB_TYPES.PLUMBING_REPAIR:
      return (
        <>
          {isNewLanding
            ? renderPrice(plumbingRepairPrice)
            : renderOriginalLandingPagePrice(
                plumbingRepairPrice,
                plumbingRepairPrice.isAmountWithVat ? 'inc. VAT*' : 'exc. VAT',
              )}
        </>
      );
  }
};

export const Tracker = ({
  showPrice,
  currentRoute = '/',
  subTitle,
  showSubtitle = true,
  inactiveHeader = false,
  mobileSubTitle,
  simplified = false,
  animationTriggerCallback,
  children,
}: PropsWithChildren<TrackerProps>) => {
  const managedRates = useManagedRates();
  const jobTypeServices = useServicesStore((state) => state.jobType);
  const jobTypeCallouts = useCalloutsStore((state) => state.jobType);
  const theme = useTheme();
  const router = useRouter();

  const isKantanPartner = currentPartner === 'KANTAN';
  const isOVOPartner = currentPartner === 'OVO';

  const {
    query: { product_type, service_type },
  } = router;
  const isRepair = router.asPath.includes('repair');
  const jobType = isRepair
    ? jobTypeCallouts ||
      queryParamToJobTypeMap(service_type) ||
      JOB_TYPES.HEATING_REPAIR
    : jobTypeServices ||
      queryParamToJobTypeMap(service_type) ||
      JOB_TYPES.BOILER_SERVICE;

  const isHomepage = currentRoute === '/';

  const rateDomain = generateRateDomain(router);
  const homePageLink = `${rateDomain}/${product_type}/${service_type}`;

  const currentSteps = useMemo(
    () =>
      steps.reduce<Record<Steps, boolean>>(
        (acc: Record<Steps, boolean>, step, index) => {
          if (index < routeToStepMap[currentRoute]) {
            return { ...acc, [step]: true };
          }

          return acc;
        },
        {} as Record<Steps, boolean>,
      ),
    [currentRoute],
  );

  const hasBeenVisited = (route: Steps) => currentSteps[route];

  const renderProgressBar = (
    route: Steps,
    step: number,
    scaleTo?: 'firstHalf' | 'secondHalf',
  ) => {
    let initialScale = 0;
    let finalScale = 1;

    const scaleMap = {
      firstHalf: {
        initialScale: 0,
        finalScale: 0.5,
      },
      secondHalf: {
        initialScale: 0.5,
        finalScale: 1,
      },
    };

    if (scaleTo) {
      initialScale = scaleMap[scaleTo].initialScale;
      finalScale = scaleMap[scaleTo].finalScale;
    }

    if (step === routeToStepMap[currentRoute]) {
      return (
        <Progress
          initial={{
            scaleX: initialScale,
          }}
          animate={{
            scaleX: finalScale,
          }}
          transition={{
            delay: 1,
            duration: 0.5,
          }}
          $variant={inactiveHeader ? 'inactive' : ''}
          $isKantan={isKantanPartner}
        />
      );
    } else if (hasBeenVisited(route)) {
      return <Progress $variant={inactiveHeader ? 'inactive' : ''} />;
    }
  };

  const renderLandingPageContent = () => {
    return (
      <Content>
        <ContentEntry>
          <CheckMark color={theme.app.text.success} />{' '}
          <ContentEntryText $regularText={isOVOPartner}>
            {jobType === JOB_TYPES.PLUMBING_REPAIR ? (
              <>
                Over <StrongText>2,000</StrongText> plumbers online
              </>
            ) : (
              <>
                Over <StrongText>2,000</StrongText> gas safe{' '}
                <StrongText>engineers</StrongText>
              </>
            )}
          </ContentEntryText>
        </ContentEntry>
        <ContentEntry>
          <CheckMark color={theme.app.text.success} />{' '}
          <ContentEntryText as="h2" $regularText={isOVOPartner}>
            {isRepair ? (
              <>
                <StrongText>Same day</StrongText> appointment
              </>
            ) : (
              <>
                Pay <StrongText>after</StrongText> the{' '}
                <StrongText>job is done</StrongText>
              </>
            )}
          </ContentEntryText>
        </ContentEntry>
        <ContentEntry>
          <CheckMark color={theme.app.text.success} />{' '}
          <ContentEntryText as="h2" $regularText={isOVOPartner}>
            {jobType === JOB_TYPES.HEATING_REPAIR ? (
              <>
                <StrongText>70%</StrongText> of repairs completed{' '}
                <StrongText>for no extra charge</StrongText>
              </>
            ) : (
              <StrongText>No hidden costs</StrongText>
            )}
          </ContentEntryText>
        </ContentEntry>
      </Content>
    );
  };

  const renderBookingFlowHeading = (subTitle?: string) => {
    if (!subTitle) return null;
    return <SubHeader>{subTitle}</SubHeader>;
  };

  const servicesStepProgressBar = () => {
    if (isRepair) {
      return renderProgressBar('/services', 2);
    } else {
      return routeToStepMap[currentRoute] === 1
        ? renderProgressBar('/services', 1, 'firstHalf')
        : renderProgressBar('/services', 2, 'secondHalf');
    }
  };

  return (
    <>
      <TrackerGrid $simplified={simplified}>
        {!simplified && (
          <>
            {showPrice ? (
              <PriceContainer>
                {renderPrice({
                  managedRates,
                  isRepair,
                  jobType,
                })}
                <Progress $variant={inactiveHeader ? 'inactive' : ''} />
                <VerticalDivider />
              </PriceContainer>
            ) : (
              <Link href={homePageLink} passHref legacyBehavior>
                <Logo>
                  {isKantanPartner ? (
                    <KantanLogo
                      color={theme.app.messages.action}
                      width={'125'}
                    />
                  ) : (
                    <OvoLogo color={theme.app.messages.logo} />
                  )}
                  <Progress $variant={inactiveHeader ? 'inactive' : ''} />
                </Logo>
              </Link>
            )}
          </>
        )}
        <Step $simplified={simplified}>
          <TrackerTypography
            $simplified={simplified}
            $variant={inactiveHeader ? 'inactive' : ''}
            $isActive={routeToStepMap[currentRoute] === 0}
            $hasBeenVisited={hasBeenVisited('/')}
          >
            1. {simplified ? 'Enter your postcode' : 'Start'}
          </TrackerTypography>
          <Progress $variant={inactiveHeader ? 'inactive' : ''} />
        </Step>
        <Step $simplified={simplified}>
          <TrackerTypography
            $simplified={simplified}
            $variant={inactiveHeader ? 'inactive' : ''}
            $isActive={[1, 2].includes(routeToStepMap[currentRoute])}
            $hasBeenVisited={hasBeenVisited('/services')}
          >
            {' '}
            2.{' '}
            {simplified ? (
              <>
                {product_type === 'repair'
                  ? 'Explain the problem'
                  : 'Choose your service'}
              </>
            ) : (
              <>
                {product_type === 'repair'
                  ? 'Describe issue'
                  : 'Select service'}
              </>
            )}
          </TrackerTypography>
          {servicesStepProgressBar()}
        </Step>
        <Step $simplified={simplified}>
          <TrackerTypography
            $simplified={simplified}
            $variant={inactiveHeader ? 'inactive' : ''}
            $isActive={routeToStepMap[currentRoute] === 3}
            $hasBeenVisited={hasBeenVisited('/select-time')}
          >
            {' '}
            3. {simplified ? 'Pick your time slot' : 'Select time'}
          </TrackerTypography>
          {renderProgressBar('/select-time', 3)}
        </Step>
        <Step $simplified={simplified}>
          <TrackerTypography
            $simplified={simplified}
            $variant={inactiveHeader ? 'inactive' : ''}
            $isActive={[4, 5].includes(routeToStepMap[currentRoute])}
            $hasBeenVisited={hasBeenVisited('/address')}
          >
            4. {simplified ? 'Add your details' : 'Your details'}
          </TrackerTypography>
          {routeToStepMap[currentRoute] === 4
            ? renderProgressBar('/address', 4, 'firstHalf')
            : renderProgressBar('/address', 5, 'secondHalf')}
        </Step>
        {!simplified && (
          <Check>
            <CheckMark
              color={
                routeToStepMap[currentRoute] === 7
                  ? theme.app.text.action
                  : undefined
              }
            />
            {renderProgressBar('/review', 6)}
          </Check>
        )}
        {currentRoute === '/' && !inactiveHeader && (
          <LandingPageContentBox $simplified={simplified}>
            {!simplified && <>{renderLandingPageContent()}</>}
            <InputBox $simplified={simplified}>
              <PostcodeInput
                simplified={simplified}
                animationTriggerCallback={animationTriggerCallback}
              />
            </InputBox>
          </LandingPageContentBox>
        )}
        {currentRoute && (showSubtitle || children) && (
          <HeaderContainer>
            {children ? children : renderBookingFlowHeading(subTitle)}
            {mobileSubTitle && (
              <SubHeader $variant="mobile">{mobileSubTitle}</SubHeader>
            )}
          </HeaderContainer>
        )}
      </TrackerGrid>
    </>
  );
};
