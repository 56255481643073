import styled from '@emotion/styled';
import { Input } from '@mui/material';
import { mediaQuery } from 'theme/kantan';

import { shouldForwardProp } from 'utils/transientStyled';

export const PostcodeContainer = styled.form<{
  $simplified?: boolean;
  $isKantan?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.app.background.white};
  padding: ${({ theme }) => theme.app.padding.smallPadding};
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  border: 4px solid ${({ theme }) => theme.app.border.focus};

  box-shadow: 0 10px 40px rgba(46, 75, 135, 0.3);

  @media (${mediaQuery('tablet')}) {
    box-shadow: unset;
    padding: ${({ theme }) => theme.app.padding.mediumPadding};
  }

  ${({ $simplified, theme }) =>
    $simplified &&
    `
        border: none; 
        padding: 0;
        box-shadow: none;

        button {
          @media (${mediaQuery('desktop')}) {
            font-size: ${theme.app.fontSettings.fontSize[24]};
            padding: ${theme.app.padding.mediumPadding} ${
      theme.app.padding.extraLargePadding
    };
          }
        }
    `}
`;

export const PostcodeInputStyles = styled(Input, {
  shouldForwardProp,
})<{
  $simplified?: boolean;
  $isKantan?: boolean;
}>`
  .MuiInput-input {
    padding: 10px 5px 7px;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};

    @media (${mediaQuery('tablet')}) {
      padding: 10px 10px 5px;

      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    }

    &::placeholder {
      font-family: ${({ theme }) => theme.app.fontSettings.fontFamily};
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
      line-height: 1.75;
      letter-spacing: -0.04em;
      color: ${({ theme }) => theme.app.text.primary};
      font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};

      @media (${mediaQuery('tablet')}) {
        font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
        line-height: 1.33;
      }

      @media (${mediaQuery('desktop')}) {
        font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
      }
    }
  }

  .MuiInput-input:disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.app.text.primary};
  }

  ${({ $simplified, $isKantan, theme }) =>
    $simplified &&
    `
      border: 2px solid ${theme.app.messages.action};
      border-radius: ${theme.app.borderRadius.mediumCorner};
      padding: ${theme.app.padding.smallMediumPadding};
      font-size: ${theme.app.fontSettings.fontSize[14]};
      margin-right: ${theme.app.margin.mediumMargin};

      svg {
        margin-right: ${theme.app.margin.mediumLargeMargin};
        width: 14px;
      }

      @media (${mediaQuery('desktop')}) {
        font-size: ${theme.app.fontSettings.fontSize[24]};
        padding: ${theme.app.padding.mediumPadding};
      }

      .MuiInput-input {
        padding: 0;

        &::placeholder {
          color: ${theme.app.landingPage.tracker.placeholder};
          opacity: 100;
        }
      }

  `}
`;
