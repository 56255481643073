import { Tooltip, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useLandingPage } from 'hooks/useLandingPage';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useAddressStore, useGenericStore } from 'store';
import { mediaQuery } from 'theme/kantan';

import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { getFlagValue } from 'utils/flags/getFlagValue';
import { trackEvent } from 'utils/tracking';
import { generateNextRoute } from 'utils/urls';
import { validatePostcode } from 'utils/validation';

import { Button } from '../../Button';
import { LocationIcon } from '../LocationIcon';
import { PostcodeContainer, PostcodeInputStyles } from './PostcodeInput.styles';

export const PostcodeInput = ({
  animationTriggerCallback,
  simplified = false,
}: {
  animationTriggerCallback?: (shouldTriggerAnimation: boolean) => void;
  simplified?: boolean;
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isPostcodeError, setIsPostcodeError] = useState(false);
  const [postcode, setState] = useAddressStore((state) => [
    state.postcode,
    state.setState,
  ]);

  const userCanEditDetails = useGenericStore(
    (state) => state.userCanEditDetails,
  );

  const { isKantanPartner } = useLandingPage();
  const router = useRouter();

  const context = useExistingStepContext();

  const { productConfig } = context;

  const form = useForm({
    mode: 'all',
    defaultValues: { postcode },
  });
  const {
    field: { onChange },
  } = useController({
    name: 'postcode',
    control: form.control,
  });

  const isTabletOrLarger = useMediaQuery(`${mediaQuery('tablet', true)}`);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const postcodeUppercase = e.target.value.toUpperCase();
    onChange(postcodeUppercase);
    setState({ postcode: postcodeUppercase });
  };

  const tooltipMessage = () => {
    return isPostcodeError
      ? 'Please enter a valid postcode'
      : 'Enter a postcode to start booking';
  };

  const ctaTextLargeScreen = () => {
    return simplified ? 'Start your booking' : 'Start booking';
  };

  const onSubmit = async () => {
    await animationTriggerCallback?.(true);
    setIsPostcodeError(false);

    if (!postcode) {
      setIsTooltipOpen(true);
      return;
    }

    const isRepairs = router.asPath.includes('repair');
    const isPlumbing = router.asPath.includes('plumbing');

    const serviceType = () => {
      if (isRepairs) {
        if (isPlumbing) {
          return 'plumbing';
        }
        return 'heating';
      }
      return (
        (router.query.service_type as string | undefined) ??
        'select-your-service'
      );
    };

    const trimmedPostcode = postcode.trim();
    const postcodeOutcode = trimmedPostcode
      .slice(0, trimmedPostcode.length - 3)
      .trim();

    try {
      const useWhitelistValidationEnabled = await getFlagValue(
        'useWhitelistValidation',
        true,
      );
      let isValid = true;

      if (useWhitelistValidationEnabled) {
        const response = await axios.post(
          `/api/validate-postcode?postcodeOutcode=${postcodeOutcode}`,
          {
            postcodeAllowList: productConfig?.postcodeAllowList ?? [],
            postcodeDenyList: productConfig?.postcodeDenyList ?? [],
          },
        );
        isValid = response.data.isValid;
      }

      if (!isValid) {
        await router.push({
          pathname: generateNextRoute({
            router,
            serviceType: serviceType(),
            nextRoute: 'no-engineers/postcode',
          }),
          query: router.query,
        });
        return;
      }

      await validatePostcode(postcode);

      trackEvent('Postcode submitted', {
        outcode: postcodeOutcode,
      });

      await router.push({
        pathname: generateNextRoute({
          router,
          serviceType: serviceType(),
          nextRoute: isRepairs || isPlumbing ? 'services' : 'fuel-type',
        }),
        query: router.query,
      });
    } catch {
      setIsTooltipOpen(true);
      setIsPostcodeError(true);
    } finally {
      if (userCanEditDetails) {
        setState({ line1: '', line2: '', town: '' });
      }
    }
  };

  useEffect(() => {
    form.reset({ postcode });
  }, [form, postcode]);

  return (
    <FormProvider {...form}>
      <PostcodeContainer
        $simplified={simplified ? simplified : undefined}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Tooltip
          title={tooltipMessage()}
          placement="bottom-start"
          open={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
        >
          <PostcodeInputStyles
            $simplified={simplified ? simplified : undefined}
            $isKantan={isKantanPartner}
            disableUnderline
            placeholder="Enter your postcode"
            fullWidth
            startAdornment={isTabletOrLarger ? <LocationIcon /> : null}
            value={postcode}
            onChange={handleChange}
            disabled={!userCanEditDetails}
          />
        </Tooltip>

        {isTabletOrLarger ? (
          <Button variant="action" type="submit" testId="submit-button">
            {ctaTextLargeScreen()}
          </Button>
        ) : (
          <Button variant="action" type="submit" testId="submit-button">
            Start
          </Button>
        )}
      </PostcodeContainer>
    </FormProvider>
  );
};
